
export default function (price:Number) {

  const integer = parseInt(price.toFixed());

  if (integer.toString().length === 4) {
        const primeraCifra = integer.toString().charAt(0); // Obtiene la primera cifra
        const restoCifras = integer.toString().substring(1); // Obtiene las cifras restantes
    return (primeraCifra+'.'+restoCifras);
  }
    return integer.toLocaleString('es-ES');
}